import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import VueLazyLoad from 'vue-lazyload'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import './assets/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus)
app.use(router)
app.use(createPinia())
app.use(VueLazyLoad, {
    preLoad: 1,
    // error: require('@/assets/images/error.png'),
    // loading: require('@/assets/images/loading.gif'),
    attempt: 2,
})
app.mount('#app')
// app.mounted(() => {
//     document.dispatchEvent(new Event('render-event'))
// })
