import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  // 首页
  {
    path: "/",
    name: "home",
    component: () => import("@/layout/index.vue"),
    redirect: '/',
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/index.vue"),
        meta: {
          content: {
            keywords: '守护签-蚂蚁链存证电子借条签约服务',
            description: '支付宝小程序【守护签】提供在线电子借条、欠条签约和管理服务，蚂蚁链登记存证可朔源，本人面部识别签约，守护信誉。'
          }
        }
      },
      // 公益合作
      {
        path: "/guarantee",
        name: "guarantee",
        component: () => import("@/views/guarantee"),
        meta: {
          title: '产品保障'
        }
      },
      // 项目
      {
        path: "/introduct",
        name: "introduct",
        component: () => import("@/views/project"),
        meta: {
          title: '产品简介'
        }
      },
    ]
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' - 守护签 - 守护钱';
  } else {
    document.title = '守护签- 守护钱';
  }
  if (to.meta.content){
    const head = document.getElementsByTagName('head');
    const meta0 = document.createElement('meta');
    meta0.name = 'keywords';
    meta0.content = to.meta.content.keywords;
    const meta1 = document.createElement('meta');
    meta1.name = 'description';
    meta1.content = to.meta.content.description;
    head[0].appendChild(meta0);
    head[0].appendChild(meta1);
  }
  next()
});
export default router;
